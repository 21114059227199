import React from "react";
import { Radio } from "@material-ui/core";

const FlightMealRegionMobile = ({
  errors,
  touched,
  values,
  setFieldValue,
  mealQuestion
}) => (
  <div className="medical-region-mobile">
    <h4 className="medicalTitleMobile">
      In-flight Meal
      {errors.inflight_meal && touched.inflight_meal && (
        <span className="errMessage">Required</span>
      )}
    </h4>
    <span className="medicalTitle">{mealQuestion}</span>
    <span className="required">*</span>
    <div className="medical-radio-mobile">
      <div className="radio-region">
        <Radio
          checked={values.inflight_meal === "yes" ? true : false}
          onChange={e => setFieldValue("inflight_meal", e.target.value)}
          value="yes"
          name="medical-radio"
        />
        <span>Yes</span>
      </div>
      <div className="radio-region">
        <Radio
          checked={values.inflight_meal === "no" ? true : false}
          onChange={e => setFieldValue("inflight_meal", e.target.value)}
          value="no"
          name="medical-radio"
        />
        <span>No</span>
      </div>
    </div>
  </div>
);

export default FlightMealRegionMobile;
