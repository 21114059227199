import React from "react";
import { Checkbox } from "@material-ui/core";

const ShuttleBusRegionMobile = ({
  values,
  setFieldValue,
  shuttleDept,
  shuttleArr
}) => {
  return (
    <>
      {((!shuttleDept && !shuttleArr) || (shuttleDept && shuttleArr)) && (
        <div className="shuttleRegion">
          <h5>Interested in shuttle bus service if available</h5>
          {((!shuttleDept && !shuttleArr) ||
            (shuttleDept && shuttleDept === "1")) && (
            <div className="shuttleBox">
              <Checkbox
                value={values.shuttle_departure}
                checked={values.shuttle_departure}
                color="primary"
                inputProps={{
                  "aria-label": "secondary checkbox"
                }}
                onChange={(e, v) => setFieldValue("shuttle_departure", v)}
              />{" "}
              <span>For Departure</span>
            </div>
          )}
          {((!shuttleDept && !shuttleArr) ||
            (shuttleArr && shuttleArr === "1")) && (
            <div className="shuttleBox">
              <Checkbox
                value={values.shuttle_arrival}
                checked={values.shuttle_arrival}
                color="primary"
                inputProps={{
                  "aria-label": "secondary checkbox"
                }}
                onChange={(e, v) => setFieldValue("shuttle_arrival", v)}
              />{" "}
              <span>For Arrival</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShuttleBusRegionMobile;
