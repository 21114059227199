import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import fly from "assets/images/fly.svg";
// import sleep from "assets/images/sleep.svg";
import "./SpacexHome.css";
import SpacexWrapper from "./SpacexWrapper";
import Axios from "axios";
import moment from "moment";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import MediaQuery from "react-responsive";
import capitalize from "lodash/capitalize";

const SpacexHome = () => {
  const [cancellations, setCancellations] = useState({});
  // const [showMore, setShowMore] = useState(false);

  const formatDate = date => moment(date).format("ddd, MMM D");
  const formatTime = date => moment(date).format("hh:mm A");
  const flightCode = string =>
    string
      .split(" ")
      .pop()
      .replace(/[()]/g, "");
  const canCancel = status =>
    ["Cancelled", "Rejected"].includes(status) === false;
  const canCancelRoom = status =>
    ["waitlisted", "waiting for guest", "confirmed"].includes(status) === true;
  const canModify = status =>
    ["pending", "checked in", "waiting for guest", "confirmed"].includes(
      status
    ) === true;
  const confirmedStatus = "Waiting for Guest Arrival";

  useEffect(() => {
    Axios.get("/api/airplane/reservations").then(res => {
      res.data && setCancellations(res.data);
      res.data &&
        res.data.last &&
        sessionStorage.setItem("last", JSON.stringify(res.data.last));
    });
    Axios.get("/api/airplane/reservations2").then(res => {
      res.data && setCancellations(res.data);
      res.data &&
        res.data.last &&
        sessionStorage.setItem("last2", JSON.stringify(res.data.last));

      res.data &&
        res.data.last &&
        sessionStorage.setItem(
          "manager_email",
          res.data.last.manager_email ? res.data.last.manager_email : ""
        );
      res.data &&
        res.data.room_quota &&
        res.data.room_quota.waitlist &&
        sessionStorage.setItem(
          "waitlistVal",
          res.data && res.data.room_quota && res.data.room_quota.waitlist
        );
    });
  }, []);

  return (
    <SpacexWrapper>
      <div className="SpacexHome">
        <div className="btn-container">
          {/* <Link className="btn-fly btn-common" to="/fly">
            <img src={fly} alt="PLANE" />
          </Link>
          <Link className="btn-sleep btn-common" to="/sleep">
            <img src={sleep} alt="HOTEL" />
          </Link> */}

          {cancellations.flights && cancellations.flights.length > 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-plane"></i> Your upcoming flights
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">Your upcoming flights</h4>
              </MediaQuery>
            </>
          )}
          {cancellations.flights && cancellations.flights.length === 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-plane"></i> You have no upcoming flights
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">You have no upcoming flights</h4>
              </MediaQuery>
            </>
          )}
          {cancellations.flights &&
            cancellations.flights.length > 0 &&
            cancellations.flights.map((flight, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>
                          Your upcoming flight for{" "}
                          {formatDate(flight.from_datetime)}
                        </p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          From: {flight.from_text}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          To: {flight.to_text} {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p>{formatDate(flight.from_datetime)}</p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.from_text)}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.to_text)}{" "}
                          {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* <p className="cancel-info">
                        In-flight meal: {flight.inflight_meal ? "Yes" : "No"}
                      </p> */}
                      <p
                        className={
                          canCancel(flight.status)
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Booking Status: {flight.status}
                      </p>

                      {flight.animal_status && flight.animal_status !== "" && (
                        <p className={"cancel-info"}>
                          Pet: {capitalize(flight.animal_status)}
                        </p>
                      )}
                    </div>
                    {canCancel(flight.status) && (
                      <div className="btn-region">
                        {!["Checked in", "No Show", "Rejected"].includes(
                          flight.status
                        ) && (
                          <Link to={"/fly/cancel/" + flight.id}>
                            <button>Cancel</button>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                  {["Checked in", "Confirmed"].includes(flight.status) && (
                    <div className="qr-region">
                      <img src={flight.qr_code_url} alt="" />
                      <span>{flight.name ? flight.name : ""}</span>
                      <span>
                        {flight.dob
                          ? moment(flight.dob, "YYYY-MM-DD").format(
                              "MM-DD-YYYY"
                            )
                          : "DOB: -"}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.flight_shared &&
            cancellations.flight_shared.length > 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-plane"></i> Shared Flight Reservation
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">Shared Flight Reservation</h4>
                </MediaQuery>
              </>
            )}
          {cancellations.flight_shared &&
            cancellations.flight_shared.length === 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-plane"></i> You have no upcoming shared
                    flight reservation
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    You have no upcoming shared flight reservation
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.flight_shared &&
            cancellations.flight_shared.length > 0 &&
            cancellations.flight_shared.map((flight, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>
                          Your upcoming flight for{" "}
                          {formatDate(flight.from_datetime)}
                        </p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          From: {flight.from_text}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          To: {flight.to_text} {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p>{formatDate(flight.from_datetime)}</p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.from_text)}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.to_text)}{" "}
                          {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* <p className="cancel-info">
                        In-flight meal: {flight.inflight_meal ? "Yes" : "No"}
                      </p> */}
                      <p
                        className={
                          canCancel(flight.status)
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Booking Status: {flight.status}
                      </p>

                      {flight.animal_status && flight.animal_status !== "" && (
                        <p className={"cancel-info"}>
                          Pet: {capitalize(flight.animal_status)}
                        </p>
                      )}
                    </div>
                    {canCancel(flight.status) && (
                      <div className="btn-region">
                        {!["Checked in", "No Show", "Rejected"].includes(
                          flight.status
                        ) && (
                          <Link to={"/fly/cancel/" + flight.id}>
                            <button>Cancel</button>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                  {["Checked in", "Confirmed"].includes(flight.status) && (
                    <div className="qr-region">
                      <img src={flight.qr_code_url} alt="" />
                      <span>{flight.name ? flight.name : ""}</span>
                      <span>
                        {flight.dob
                          ? moment(flight.dob, "YYYY-MM-DD").format(
                              "MM-DD-YYYY"
                            )
                          : "DOB: -"}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.flight_family_friends &&
            cancellations.flight_family_friends.length > 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-plane"></i> Your upcoming
                    family/friend’s flights
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    Your upcoming family/friend’s flights
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.flight_family_friends &&
            cancellations.flight_family_friends.length === 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-plane"></i> You have no upcoming
                    family/friend’s flights
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    You have no upcoming family/friend’s flights
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.flight_family_friends &&
            cancellations.flight_family_friends.length > 0 &&
            cancellations.flight_family_friends.map((flight, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>
                          Your upcoming flight for{" "}
                          {formatDate(flight.from_datetime)}
                        </p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          From: {flight.from_text}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          To: {flight.to_text} {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p>{formatDate(flight.from_datetime)}</p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.from_text)}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.to_text)}{" "}
                          {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* <p className="cancel-info">
                        In-flight meal: {flight.inflight_meal ? "Yes" : "No"}
                      </p> */}
                      <p
                        className={
                          canCancel(flight.status)
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Booking Status: {flight.status}
                      </p>
                      {flight.animal_status && flight.animal_status !== "" && (
                        <p className={"cancel-info"}>
                          Pet: {capitalize(flight.animal_status)}
                        </p>
                      )}
                    </div>
                    {canCancel(flight.status) && (
                      <div className="btn-region">
                        {!["Checked in", "No Show", "Rejected"].includes(
                          flight.status
                        ) && (
                          <Link to={"/fly/cancel/" + flight.id}>
                            <button>Cancel</button>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                  {["Checked in", "Confirmed"].includes(flight.status) && (
                    <div className="qr-region">
                      <img src={flight.qr_code_url} alt="" />
                      <span>{flight.name ? flight.name : ""}</span>
                      <span>
                        {flight.dob
                          ? moment(flight.dob, "YYYY-MM-DD").format(
                              "MM-DD-YYYY"
                            )
                          : "DOB: -"}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.flight_family_friend_shared &&
            cancellations.flight_family_friend_shared.length > 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-plane"></i> Shared Family/Friends Flight
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    Shared Family/Friends Flight
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.flight_family_friend_shared &&
            cancellations.flight_family_friend_shared.length === 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-plane"></i> You have no upcoming shared
                    family/friends flight reservation
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    You have no upcoming shared family/friends flight
                    reservation
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.flight_family_friend_shared &&
            cancellations.flight_family_friend_shared.length > 0 &&
            cancellations.flight_family_friend_shared.map((flight, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>
                          Your upcoming flight for{" "}
                          {formatDate(flight.from_datetime)}
                        </p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          From: {flight.from_text}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          To: {flight.to_text} {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p>{formatDate(flight.from_datetime)}</p>
                        <p className="cancel-info">
                          Name: {flight.name ? flight.name : ""}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.from_text)}{" "}
                          {formatTime(flight.from_datetime)}{" "}
                          {flight.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          {flightCode(flight.to_text)}{" "}
                          {formatTime(flight.to_datetime)}{" "}
                          {flight.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* <p className="cancel-info">
                        In-flight meal: {flight.inflight_meal ? "Yes" : "No"}
                      </p> */}
                      <p
                        className={
                          canCancel(flight.status)
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Booking Status: {flight.status}
                      </p>

                      {flight.animal_status && flight.animal_status !== "" && (
                        <p className={"cancel-info"}>
                          Pet: {capitalize(flight.animal_status)}
                        </p>
                      )}
                    </div>
                    {canCancel(flight.status) && (
                      <div className="btn-region">
                        {!["Checked in", "No Show", "Rejected"].includes(
                          flight.status
                        ) && (
                          <Link to={"/fly/cancel/" + flight.id}>
                            <button>Cancel</button>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                  {["Checked in", "Confirmed"].includes(flight.status) && (
                    <div className="qr-region">
                      <img src={flight.qr_code_url} alt="" />
                      <span>{flight.name ? flight.name : ""}</span>
                      <span>
                        {flight.dob
                          ? moment(flight.dob, "YYYY-MM-DD").format(
                              "MM-DD-YYYY"
                            )
                          : "DOB: -"}
                      </span>
                    </div>
                  )}
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.hotels && cancellations.hotels.length > 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-bed"></i> Your upcoming room reservation
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">
                  Your upcoming room reservation
                </h4>
              </MediaQuery>
            </>
          )}
          {cancellations.hotels && cancellations.hotels.length === 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-bed"></i> You have no upcoming room
                  reservation
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">
                  You have no upcoming room reservation
                </h4>
              </MediaQuery>
            </>
          )}
          {cancellations.hotels &&
            cancellations.hotels.map((hotel, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>Your upcoming room in Boca Chica</p>
                        <p className="cancel-info">
                          Name: {hotel.name ? hotel.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(hotel.check_in)} to{" "}
                          {formatDate(hotel.check_out)}
                        </p>
                      </MediaQuery>
                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p className="cancel-info">
                          Name: {hotel.name ? hotel.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(hotel.check_in)} to{" "}
                          {formatDate(hotel.check_out)}
                        </p>
                      </MediaQuery>
                      <p
                        className={
                          canCancelRoom(hotel.status.toLowerCase())
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Status:{" "}
                        {hotel.status.toLowerCase() === "waiting for guest"
                          ? confirmedStatus
                          : hotel.status}
                      </p>
                    </div>
                    {/* Desktop or laptop */}
                    <MediaQuery minWidth={780}>
                      {canModify(hotel.status.toLowerCase()) && (
                        <div className="btn-modify">
                          <Link
                            to={`/sleep/modify/personal-information/${
                              hotel.id
                            }/${
                              hotel.status.toLowerCase() === "checked in"
                                ? 1
                                : 0
                            }`}
                          >
                            <button>Modify</button>
                          </Link>
                        </div>
                      )}
                      {canCancelRoom(hotel.status.toLowerCase()) && (
                        <div className="btn-region">
                          <Link to={"/sleep/cancel/" + hotel.id}>
                            <button>Cancel</button>
                          </Link>
                        </div>
                      )}
                    </MediaQuery>
                    {/* Tablet or Mobile */}
                    <MediaQuery maxWidth={780}>
                      <div className="btns">
                        {canModify(hotel.status.toLowerCase()) && (
                          <div className="btn-modify">
                            <Link
                              to={`/sleep/modify/personal-information/${
                                hotel.id
                              }/${
                                hotel.status.toLowerCase() === "checked in"
                                  ? 1
                                  : 0
                              }`}
                            >
                              <button>Modify</button>
                            </Link>
                          </div>
                        )}
                        {canCancelRoom(hotel.status.toLowerCase()) && (
                          <div className="btn-region">
                            <Link to={"/sleep/cancel/" + hotel.id}>
                              <button>Cancel</button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </MediaQuery>
                  </div>
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.hotel_shared && cancellations.hotel_shared.length > 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-bed"></i> Shared Room Reservation
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">Shared Room Reservation</h4>
              </MediaQuery>
            </>
          )}
          {cancellations.hotel_shared &&
            cancellations.hotel_shared.length === 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-bed"></i> You have no upcoming shared
                    room reservation
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    You have no upcoming shared room reservation
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.hotel_shared &&
            cancellations.hotel_shared.map((hotel, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>Your upcoming room in Boca Chica</p>
                        <p className="cancel-info">
                          Name: {hotel.name ? hotel.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(hotel.check_in)} to{" "}
                          {formatDate(hotel.check_out)}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p className="cancel-info">
                          Name: {hotel.name ? hotel.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(hotel.check_in)} to{" "}
                          {formatDate(hotel.check_out)}
                        </p>
                      </MediaQuery>

                      <p
                        className={
                          canCancelRoom(hotel.status.toLowerCase())
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Status:{" "}
                        {hotel.status.toLowerCase() === "waiting for guest"
                          ? confirmedStatus
                          : hotel.status}
                      </p>
                    </div>
                    {canCancelRoom(hotel.status.toLowerCase()) && (
                      <div className="btn-region">
                        <Link to={"/sleep/cancel/" + hotel.id}>
                          <button>Cancel</button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.hotel_family_friends &&
            cancellations.hotel_family_friends.length > 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-bed"></i> Your upcoming family/friends
                    room reservation
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    Your upcoming family/friends room reservation
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.hotel_family_friends &&
            cancellations.hotel_family_friends.length === 0 && (
              <>
                {/* Desktop or laptop */}
                <MediaQuery minWidth={780}>
                  <h4 className="upcoming-title">
                    <i className="fa fa-bed"></i> You have no upcoming
                    family/friends room reservation
                  </h4>
                </MediaQuery>
                {/* Tablet or Mobile */}
                <MediaQuery maxWidth={780}>
                  <h4 className="upcoming-title">
                    You have no upcoming family/friends room reservation
                  </h4>
                </MediaQuery>
              </>
            )}
          {cancellations.hotel_family_friends &&
            cancellations.hotel_family_friends.map((hotel, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>Your upcoming room in Boca Chica</p>
                        <p className="cancel-info">
                          Name: {hotel.name ? hotel.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(hotel.check_in)} to{" "}
                          {formatDate(hotel.check_out)}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p className="cancel-info">
                          Name: {hotel.name ? hotel.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(hotel.check_in)} to{" "}
                          {formatDate(hotel.check_out)}
                        </p>
                      </MediaQuery>

                      <p
                        className={
                          canCancelRoom(hotel.status.toLowerCase())
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Status:{" "}
                        {hotel.status.toLowerCase() === "waiting for guest"
                          ? confirmedStatus
                          : hotel.status}
                      </p>
                    </div>
                    {/* Desktop or laptop */}
                    <MediaQuery minWidth={780}>
                      {canModify(hotel.status.toLowerCase()) && (
                        <div className="btn-modify">
                          <Link
                            to={`/sleep/modify/personal-information/${
                              hotel.id
                            }/${
                              hotel.status.toLowerCase() === "checked in"
                                ? 1
                                : 0
                            }`}
                          >
                            <button>Modify</button>
                          </Link>
                        </div>
                      )}
                      {canCancelRoom(hotel.status.toLowerCase()) && (
                        <div className="btn-region">
                          <Link to={"/sleep/cancel/" + hotel.id}>
                            <button>Cancel</button>
                          </Link>
                        </div>
                      )}
                    </MediaQuery>
                    {/* Tablet or Mobile */}
                    <MediaQuery maxWidth={780}>
                      <div className="btns">
                        {canModify(hotel.status.toLowerCase()) && (
                          <div className="btn-modify">
                            <Link
                              to={`/sleep/modify/personal-information/${
                                hotel.id
                              }/${
                                hotel.status.toLowerCase() === "checked in"
                                  ? 1
                                  : 0
                              }`}
                            >
                              <button>Modify</button>
                            </Link>
                          </div>
                        )}
                        {canCancelRoom(hotel.status.toLowerCase()) && (
                          <div className="btn-region">
                            <Link to={"/sleep/cancel/" + hotel.id}>
                              <button>Cancel</button>
                            </Link>
                          </div>
                        )}
                      </div>
                    </MediaQuery>
                  </div>
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.hovercrafts && cancellations.hovercrafts.length > 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-bed"></i> Your upcoming hovercraft
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">Your upcoming hovercraft</h4>
              </MediaQuery>
            </>
          )}
          {cancellations.hovercrafts && cancellations.hovercrafts.length === 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-ship"></i> You have no upcoming hovercraft
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">
                  You have no upcoming hovercraft
                </h4>
              </MediaQuery>
            </>
          )}
          {cancellations.hovercrafts &&
            cancellations.hovercrafts.length > 0 &&
            cancellations.hovercrafts.map((craft, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>
                          Your hovercraft reservation{" "}
                          {formatDate(craft.from_datetime)}
                        </p>
                        <p className="cancel-info">
                          Name: {craft.name ? craft.name : ""}
                        </p>
                        <p className="cancel-info">
                          From: {craft.from_text}{" "}
                          {formatTime(craft.from_datetime)}{" "}
                          {craft.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          To: {craft.to_text} {formatTime(craft.to_datetime)}{" "}
                          {craft.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p>{formatDate(craft.from_datetime)}</p>
                        <p className="cancel-info">
                          Name: {craft.name ? craft.name : ""}
                        </p>
                        <p className="cancel-info">
                          {flightCode(craft.from_text)}{" "}
                          {formatTime(craft.from_datetime)}{" "}
                          {craft.from_timezone.abbr.toUpperCase()}
                        </p>
                        <p className="cancel-info">
                          {flightCode(craft.to_text)}{" "}
                          {formatTime(craft.to_datetime)}{" "}
                          {craft.to_timezone.abbr.toUpperCase()}
                        </p>
                      </MediaQuery>

                      <p
                        className={
                          canCancel(craft.status)
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Status: {craft.status}
                      </p>
                    </div>
                    {canCancel(craft.status) && (
                      <div className="btn-region">
                        {!["Checked in", "No Show", "Rejected"].includes(
                          craft.status
                        ) && (
                          <Link to={"/hovercraft/cancel/" + craft.id}>
                            <button>Cancel</button>
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          <hr className="upcoming-title"></hr>

          {cancellations.cars && cancellations.cars.length > 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-car"></i> Your upcoming car reservation
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">
                  Your upcoming car reservation
                </h4>
              </MediaQuery>
            </>
          )}
          {cancellations.cars && cancellations.cars.length === 0 && (
            <>
              {/* Desktop or laptop */}
              <MediaQuery minWidth={780}>
                <h4 className="upcoming-title">
                  <i className="fa fa-car"></i> You have no upcoming car
                  reservation
                </h4>
              </MediaQuery>
              {/* Tablet or Mobile */}
              <MediaQuery maxWidth={780}>
                <h4 className="upcoming-title">
                  You have no upcoming car reservation
                </h4>
              </MediaQuery>
            </>
          )}
          {cancellations.cars &&
            cancellations.cars.map((car, key) => {
              return (
                <div key={key} className="fly-cancel-div">
                  <div className="info-region">
                    <div className="info-text">
                      {/* Desktop or laptop */}
                      <MediaQuery minWidth={780}>
                        <p>Your car reservation</p>
                        <p className="cancel-info">
                          Name: {car.name ? car.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(car.check_in)}
                        </p>
                      </MediaQuery>

                      {/* Tablet or Mobile */}
                      <MediaQuery maxWidth={780}>
                        <p className="cancel-info">
                          Name: {car.name ? car.name : ""}
                        </p>
                        <p className="cancel-info">
                          {formatDate(car.check_in)}
                        </p>
                      </MediaQuery>

                      <p
                        className={
                          canCancel(car.status)
                            ? "cancel-info"
                            : "cancel-info-red"
                        }
                      >
                        Status:{" "}
                        {car.status.toLowerCase() === "reserved"
                          ? "Reserved (waiting for approval)"
                          : car.status}
                      </p>
                    </div>
                    {canCancel(car.status) && (
                      <div className="btn-region">
                        <Link to={"/car/cancel/" + car.id}>
                          <button>Cancel</button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          {/* <span className="fly-info-text">
            This is a booking tool only for Starship travel for private
            chartered flights to and from Boca &amp; SpaceX owned Boca Village
            accommodations. To book travel through our SpaceX travel program,
            please visit{" "}
            <a href="https://travel/" target="_blank" rel="noopener noreferrer">
              travel
            </a>{" "}
            / (SpaceX VPN)
          </span> */}
        </div>
      </div>
    </SpacexWrapper>
  );
};

export default SpacexHome;
