import React from "react";
import { Grid, Checkbox, FormControlLabel } from "@material-ui/core";

const ShuttleBusRegionWeb = ({
  values,
  setFieldValue,
  shuttleDept,
  shuttleArr
}) => {
  return (
    <>
      {((!shuttleDept && !shuttleArr) || (shuttleDept && shuttleArr)) && (
        <Grid container className="shuttleBusFields">
          <h5>Interested in shuttle bus service if available</h5>
          {((!shuttleDept && !shuttleArr) ||
            (shuttleDept && shuttleDept === "1")) && (
            <Grid item sm={12} className="shuttleBox">
              <FormControlLabel
                control={
                  <Checkbox
                    value={values.shuttle_departure}
                    checked={values.shuttle_departure}
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                    onChange={(e, v) => setFieldValue("shuttle_departure", v)}
                  />
                }
                label="For Departure"
              ></FormControlLabel>
            </Grid>
          )}
          {((!shuttleDept && !shuttleArr) ||
            (shuttleArr && shuttleArr === "1")) && (
            <Grid item sm={12} className="shuttleBox">
              <FormControlLabel
                control={
                  <Checkbox
                    value={values.shuttle_arrival}
                    checked={values.shuttle_arrival}
                    color="primary"
                    inputProps={{
                      "aria-label": "secondary checkbox"
                    }}
                    onChange={(e, v) => setFieldValue("shuttle_arrival", v)}
                  />
                }
                label="For Arrival"
              ></FormControlLabel>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default ShuttleBusRegionWeb;
