import React from "react";
import { Grid, Radio } from "@material-ui/core";

const MedicalInfoRegionWeb = ({
  cName,
  errors,
  touched,
  values,
  setFieldValue
}) => (
  <Grid container className="medicalFields">
    <Grid item sm={12} className={cName}>
      <h5>
        Medical Information
        {errors.has_medical && touched.has_medical && (
          <span className="errMessage">{errors.has_medical}</span>
        )}
      </h5>
      <span className="medicalTitle">
        Do you have any medical conditions, allergies or special needs that the
        flight crew should be aware of prior to flight? (Yes or No)
      </span>
      <span className="required">*</span>
    </Grid>
    <Grid item sm={12} className="radio-group">
      <div className="radio-region">
        <Radio
          checked={values.has_medical === "yes" ? true : false}
          onChange={e => setFieldValue("has_medical", e.target.value)}
          value="yes"
          name="medical-radio"
        />
        <span>Yes</span>
      </div>
      <div className="radio-region">
        <Radio
          checked={values.has_medical === "no" ? true : false}
          onChange={e => setFieldValue("has_medical", e.target.value)}
          value="no"
          name="medical-radio"
        />
        <span>No</span>
      </div>
    </Grid>
    {values.has_medical === "yes" && (
      <>
        <Grid item sm={12}>
          <span className="fieldTitle">
            If so, please provide a brief summary
          </span>
        </Grid>
        <Grid item sm={6} className="radio-summary">
          <textarea
            name="summary"
            rows={4}
            className={
              errors.medical_summary && touched.medical_summary
                ? "err-summary"
                : "text-summary"
            }
            onChange={e => setFieldValue("medical_summary", e.target.value)}
            value={values.medical_summary}
          ></textarea>
          {errors.medical_summary && touched.medical_summary && (
            <span className="errMessage">{errors.medical_summary}</span>
          )}
        </Grid>
      </>
    )}
    <Grid item sm={12} className="p-bottom-15 medical-divider"></Grid>
  </Grid>
);

export default MedicalInfoRegionWeb;
