import React from "react";
import MediaQuery from "react-responsive";
import FlyCustomerInfoWeb from "pages/spacex/fly/FlyCustomerInfoWeb";
import FlyCustomerInfoMobile from "pages/spacex/fly/FlyCustomerInfoMobile";
import { withRouter } from "react-router";
import { withFormik } from "formik";
import FlyValidationSchema from "./FlyValidationSchema.js";
import { compose } from "recompose";
import { SPACEX_LOGIN_EMAIL } from "config/constants";

const MyForm = props => {
  const handleContactInfo = value => {
    let { setFieldValue, values } = props;
    if (value) {
      setFieldValue("adultInfo[0].title", values.gender);
      setFieldValue("adultInfo[0].firstName", values.firstName);
      setFieldValue("adultInfo[0].lastName", values.lastName);
      setFieldValue("adultInfo[0].mobileNo", values.mobileNo);
      setFieldValue("adultInfo[0].email", values.email);
      setFieldValue("adultInfo[0].cc_email", values.cc_email);
      setFieldValue("adultInfo[0].manager_email", values.manager_email);
    } else {
      setFieldValue("adultInfo[0].title", "Mr.");
      setFieldValue("adultInfo[0].firstName", "");
      setFieldValue("adultInfo[0].lastName", "");
      setFieldValue("adultInfo[0].mobileNo", "");
      setFieldValue("adultInfo[0].email", "");
      setFieldValue("adultInfo[0].cc_email", "");
      setFieldValue("adultInfo[0].manager_email", "");
    }
    setFieldValue("isChecked", value);
  };

  return (
    <>
      <MediaQuery minWidth={1025}>
        <FlyCustomerInfoWeb
          returnFlightDetailInfo={props.returnFlightDetailInfo}
          pageName={props.values.pageName}
          {...props}
          handleContactInfo={handleContactInfo}
          isLoading={props.values.isLoading}
          submitText={props.submitText}
          isCharter={props.isCharter}
          flightId={props.flightId}
          flightArr={props.flightArr}
          isMulitple={props.isMulitple}
          userData={props.userData}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <FlyCustomerInfoMobile
          pageName={props.values.pageName}
          {...props}
          handleContactInfo={handleContactInfo}
          isLoading={props.values.isLoading}
          pageContent={props.pageContent}
          isContinue={props.isContinue}
          submitText={props.submitText}
          isCharter={props.isCharter}
          flightId={props.flightId}
          flightArr={props.flightArr}
          isMulitple={props.isMulitple}
          userData={props.userData}
        />
      </MediaQuery>
    </>
  );
};

const normalizeData = values => {
  let dataResult = { data: {} };
  dataResult.data["customer_email"] = values.multiEmail;
  dataResult.data["cc_email"] = values.cc_email;
  dataResult.data["manager_email"] = values.manager_email;
  dataResult.data["customer_gender"] = values.gender;
  dataResult.data["customer_name"] =
    values.firstName + " " + values.middleName + " " + values.lastName;
  dataResult.data["customer_first_name"] = values.firstName;
  dataResult.data["customer_middle_name"] = values.middleName;
  dataResult.data["customer_last_name"] = values.lastName;
  dataResult.data["customer_phone"] = values.mobileNo;
  dataResult.data["purpose"] = values.purpose;
  dataResult.data["report"] = values.report;
  dataResult.data["length_of_stay"] = values.length_of_stay;
  dataResult.data["inventory_weight"] = values.inventory_weight;
  dataResult.data["inventory_dimension_h"] = values.inventory_dimension_h;
  dataResult.data["inventory_dimension_w"] = values.inventory_dimension_w;
  dataResult.data["inventory_dimension_l"] = values.inventory_dimension_l;
  dataResult.data["hazardous"] = values.hazardous;
  dataResult.data["additional_weight"] = values.additional_weight;
  dataResult.data["additional_dimension_h"] = values.additional_dimension_h;
  dataResult.data["additional_dimension_w"] = values.additional_dimension_w;
  dataResult.data["charter"] = values.charter;
  dataResult.data["dob"] =
    values.dob && values.dob !== "" ? values.dob.format("MM/DD/YYYY") : "";
  dataResult.data["dobDay"] = values.dobDay;
  dataResult.data["dobMonth"] = values.dobMonth;
  dataResult.data["dobYear"] = values.dobYear;
  dataResult.data["weight"] = values.weight;
  dataResult.data["baggage"] = values.baggage;
  dataResult.data["carry_on"] = values.carry_on;
  dataResult.data["checked_bags"] = values.checked_bags;
  dataResult.data["carry_on_weight"] = values.carry_on_weight;
  dataResult.data["animal"] = values.animal;
  dataResult.data["animal_type"] = values.animal_type;
  dataResult.data["animal_size"] = values.animal_size;
  dataResult.data["animal_id"] = values.animal_id;
  dataResult.data["animal_service"] = values.animal_service;
  dataResult.data["animal_weight"] = values.animal_weight;
  dataResult.data["animal_name"] = values.animal_name;
  dataResult.data["animal_detail"] = values.animal_detail;
  dataResult.data["family_friend"] = values.family_friend;
  //dataResult.data["family_name"] = values.family_name;
  dataResult.data["family_gender"] = values.family_gender;
  dataResult.data["family_first_name"] = values.family_first_name;
  dataResult.data["family_middle_name"] = values.family_middle_name;
  dataResult.data["family_last_name"] = values.family_last_name;
  dataResult.data["family_relationship"] = values.family_relationship;
  dataResult.data["family_email"] = values.family_email;
  dataResult.data["family_phone"] = values.family_phone;
  //dataResult.data["shuttle_bus"] = values.shuttle_bus;
  dataResult.data["has_medical"] = values.has_medical === "yes" ? true : false;
  dataResult.data["inflight_meal"] =
    values.inflight_meal === "yes" ? true : false;
  dataResult.data["medical_summary"] = values.medical_summary;
  dataResult.data["shuttle_departure"] = values.shuttle_departure;
  dataResult.data["shuttle_arrival"] = values.shuttle_arrival;
  return dataResult;
};

const getLastData = () => JSON.parse(sessionStorage.getItem("last2"));

const FlyCustomerDetailForm = compose(
  withRouter,
  withFormik({
    mapPropsToValues: props => {
      let last = getLastData();
      let phone = last && last.phone ? last.phone : "";

      return {
        gender:
          !props.isMultiple && props.userData && props.userData.gender
            ? props.userData.gender
            : last && last.gender
            ? last.gender
            : "",
        firstName:
          !props.isMultiple && props.userData && props.userData.first_name
            ? props.userData.first_name
            : last && last.first_name
            ? last.first_name
            : "",
        middleName:
          !props.isMultiple && props.userData && props.userData.middle_name
            ? props.userData.middle_name
            : last && last.middle_name
            ? last.middle_name
            : "",
        lastName:
          !props.isMultiple && props.userData && props.userData.last_name
            ? props.userData.last_name
            : last && last.last_name
            ? last.last_name
            : "",
        mobileNo:
          !props.isMultiple && props.userData && props.userData.phone
            ? props.userData.phone
            : phone,
        multiEmail: sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          ? sessionStorage.getItem(SPACEX_LOGIN_EMAIL)
          : "",
        email: "",
        cc_email: "",
        manager_email: last && last.manager_email ? last.manager_email : "",
        utmCampaign: props.utmCampaign
          ? props.utmCampaign === "corporatepilot"
            ? props.utmCampaign
            : null
          : null,
        bookingId: "",
        paymentMethod: "",
        isChecked: false,
        isLoading: false,
        isConfirm: false,
        inventory_weight: 0,
        inventory_dimension_h: 0,
        inventory_dimension_w: 0,
        inventory_dimension_l: 0,
        baggage_type: "ft",
        hazardous: "",
        additional_weight: 0,
        additional_dimension_h: 0,
        additional_dimension_w: 0,
        additional_dimension_l: 0,
        report: last && last.report ? last.report : "",
        purpose: last && last.purpose ? last.purpose : "",
        length_of_stay: "",
        charter: props.isCharter,
        dob: "",
        dobDay: "00",
        dobMonth: "00",
        dobYear: "0000",
        weight: "",
        baggage: "",
        carry_on: "0",
        checked_bags: "0",
        carry_on_weight: "",
        animal: false,
        animal_id: "",
        animal_type: "",
        animal_size: "",
        animal_name: "",
        animal_service: "",
        animal_service_warning: false,
        animal_weight: "",
        animal_detail: "",
        family_friend: 0,
        //family_name: "",
        family_gender: "",
        family_first_name: "",
        family_middle_name: "",
        family_last_name: "",
        family_relationship: "",
        family_email: "",
        family_phone: "",
        shuttle_bus: "",
        has_medical: "no",
        inflight_meal: "no",
        medical_summary: "",
        shuttle_departure: false,
        shuttle_arrival: false
      };
    },

    validationSchema: props => FlyValidationSchema(props),

    handleSubmit: (values, props) => {
      props.setFieldValue("isLoading", true);
      let data = normalizeData(values);
      props.props.handleReservation(data.data);
    },

    displayName: "BasicForm"
  })
)(MyForm);

export default FlyCustomerDetailForm;
