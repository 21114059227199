import React from "react";
import { Grid, Radio } from "@material-ui/core";

const FlightMealRegionWeb = ({
  errors,
  touched,
  values,
  setFieldValue,
  mealQuestion
}) => (
  <Grid container className="medicalFields">
    <Grid item sm={12}>
      <h5>
        In-flight Meal
        {errors.inflight_meal && touched.inflight_meal && (
          <span className="errMessage">Required</span>
        )}
      </h5>
      <div className="medical-flex">
        <span className="medicalTitle">{mealQuestion}</span>
        <span className="required">*</span>
      </div>
    </Grid>
    <Grid item sm={12} className="radio-group">
      <div className="radio-region">
        <Radio
          checked={values.inflight_meal === "yes" ? true : false}
          onChange={e => setFieldValue("inflight_meal", e.target.value)}
          value="yes"
          name="medical-radio"
        />
        <span>Yes</span>
      </div>
      <div className="radio-region">
        <Radio
          checked={values.inflight_meal === "no" ? true : false}
          onChange={e => setFieldValue("inflight_meal", e.target.value)}
          value="no"
          name="medical-radio"
        />
        <span>No</span>
      </div>
    </Grid>
    <Grid item sm={12} className="p-bottom-15 medical-divider"></Grid>
  </Grid>
);

export default FlightMealRegionWeb;
