import * as Yup from "yup";

const FlyValidationSchema = props => {
  return Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    gender: Yup.string().when("family_friend", {
      is: 0,
      then: Yup.string().required("Required")
    }),
    email: Yup.array()
      .transform(function(value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
    mobileNo: Yup.string().required("Required"),
    report: Yup.string().when("family_friend", {
      is: 0,
      then: Yup.string().required("Required")
    }),
    purpose: Yup.string().when("family_friend", {
      is: 0,
      then: Yup.string()
        .required("Required")
        .test("purpose", value => {
          if (value === "-") {
            return false;
          } else {
            return true;
          }
        })
    }),

    length_of_stay: Yup.string().when("family_friend", {
      is: 0,
      then: props.isBrownsville
        ? Yup.string().required("Required")
        : Yup.string().notRequired()
    }),

    dobDay: Yup.string().when("charter", {
      is: true,
      then: Yup.string()
        .required("Required")
        .test("dobDay", "Required", value => {
          return value === "00" ? false : true;
        }),
      otherwise: Yup.string().when("family_friend", {
        is: 1,
        then: Yup.string()
          .required("Required")
          .test("dobDay", "Required", value => {
            return value === "00" ? false : true;
          })
      })
    }),
    dobMonth: Yup.string().when("charter", {
      is: true,
      then: Yup.string()
        .required("Required")
        .test("dobMonth", "Required", value => {
          return value === "00" ? false : true;
        }),
      otherwise: Yup.string().when("family_friend", {
        is: 1,
        then: Yup.string()
          .required("Required")
          .test("dobMonth", "Required", value => {
            return value === "00" ? false : true;
          })
      })
    }),

    dobYear: Yup.string().when("charter", {
      is: true,
      then: Yup.string()
        .required("Required")
        .test("dobYear", "Required", value => {
          return value === "0000" ? false : true;
        }),
      otherwise: Yup.string().when("family_friend", {
        is: 1,
        then: Yup.string()
          .required("Required")
          .test("dobYear", "Required", value => {
            return value === "0000" ? false : true;
          })
      })
    }),

    weight: Yup.string().when("charter", {
      is: true,
      then: Yup.string().required("Required")
    }),
    carry_on: Yup.string().when("charter", {
      is: true,
      then: Yup.string().required("Required")
    }),
    checked_bags: Yup.string().when("charter", {
      is: true,
      then: Yup.string().required("Required")
    }),
    carry_on_weight: Yup.string().when("charter", {
      is: true,
      then: Yup.string().required("Put 0 if brings nothing")
    }),
    baggage: Yup.string().when("charter", {
      is: true,
      then: Yup.string().required("Put 0 if brings nothing")
    }),
    animal_type: Yup.string().when("animal", {
      is: true,
      then: Yup.string().required("Required")
    }),
    animal_size: Yup.string().when("animal", {
      is: true,
      then: Yup.string().required("Required")
    }),
    animal_name: Yup.string().when("animal", {
      is: true,
      then: Yup.string().required("Required")
    }),
    // animal_detail: Yup.string().when("animal", {
    //   is: true,
    //   then: Yup.string().required("Required")
    // }),
    animal_service: Yup.string().when("animal", {
      is: true,
      then: Yup.string().required("Required")
    }),
    animal_weight: Yup.string().when("animal", {
      is: true,
      then: Yup.string().required("Required")
    }),
    family_gender: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),
    family_first_name: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),
    family_last_name: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),
    family_relationship: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),
    family_email: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string()
        .email(({ value }) => `${value} is not a valid email`)
        .required("Required")
    }),
    family_phone: Yup.string().when("family_friend", {
      is: 1,
      then: Yup.string().required("Required")
    }),

    has_medical: Yup.string().required("Required"),
    medical_summary: Yup.string().when("has_medical", {
      is: "yes",
      then: Yup.string().required("Required")
    }),

    inflight_meal: Yup.string().required("Required")
  });
};

export default FlyValidationSchema;
