import React from "react";
import { Grid, Radio } from "@material-ui/core";

const MedicalInfoRegionMobile = ({
  errors,
  touched,
  values,
  setFieldValue
}) => (
  <div className="medical-region-mobile">
    <h4 className="medicalTitleMobile">
      Medical Information{" "}
      {errors.has_medical && touched.has_medical && (
        <span className="errMessage">Required</span>
      )}
    </h4>
    <span className="medicalTitle">
      Do you have any medical conditions, allergies or special needs that the
      flight crew should be aware of prior to flight? (Yes or No)
    </span>
    <span className="required">*</span>
    <div className="medical-radio-mobile">
      <div className="radio-region">
        <Radio
          checked={values.has_medical === "yes" ? true : false}
          onChange={e => setFieldValue("has_medical", e.target.value)}
          value="yes"
          name="medical-radio"
        />
        <span>Yes</span>
      </div>
      <div className="radio-region">
        <Radio
          checked={values.has_medical === "no" ? true : false}
          onChange={e => setFieldValue("has_medical", e.target.value)}
          value="no"
          name="medical-radio"
        />
        <span>No</span>
      </div>
    </div>
    {values.has_medical === "yes" && (
      <>
        <Grid item xs={12}>
          <span className="fieldTitle">
            If so, please provide a brief summary
          </span>
        </Grid>
        <Grid item xs={12} className="radio-summary">
          <textarea
            name="summary"
            rows={4}
            className={
              errors.medical_summary && touched.medical_summary
                ? "err-summary"
                : "text-summary"
            }
            onChange={e => setFieldValue("medical_summary", e.target.value)}
            value={values.medical_summary}
          ></textarea>
          {errors.medical_summary && touched.medical_summary && (
            <span className="errMessage">{errors.medical_summary}</span>
          )}
        </Grid>
      </>
    )}
  </div>
);

export default MedicalInfoRegionMobile;
